@use 'theme';

@mixin lockWidth($width) {
  min-width: $width;
  width: $width;
  max-width: $width;
}

@mixin lockHeight($width) {
  min-height: $width;
  height: $width;
  max-height: $width;
}

@mixin hideLink {
  text-decoration: none;
  color: theme.color('text');
}

@mixin hideButton {
  background: transparent;
  border: none;
  text-align: unset;
  padding: 0;
  font-size: 1rem;
}

@mixin aspectRatioFullHeight($aspectRatio) {
  height: 100%;
  padding-top: 100% / $aspectRatio;
}

@mixin aspectRatioFullWidth($aspectRatio) {
  width: 100%;
  padding-top: 100% / $aspectRatio;
}

@mixin flex($direction: 'column') {
  display: flex;
  flex-direction: #{$direction};
}

@mixin centerBackgroundImage {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin absoluteFill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin page {
  padding-top: calc(#{theme.spacing(1.25)} + 1vw);
  padding-bottom: calc(#{theme.spacing(1.25)} + 1vw);
  flex: 1;
}

@mixin pageCompact {
  padding-top: theme.spacing(2);
  padding-bottom: theme.spacing(2);
  flex: 1;
}

@mixin card {
  border-radius: theme.roundness(1);
  overflow: hidden;
  position: relative;
}

@mixin cardBody {
  padding: theme.spacing(2);
}

@mixin unstyle {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

@mixin accessibilityOutline {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}